import * as actionTypes from '../actions/actionTypes'

const initialState = {
  currentPage: 1,
  rowsPerPage: 5,
  totalPages: 1,
}

const reducer = (state = initialState, action)=>{
    switch (action.type) {
        case actionTypes.SET_CURRENT_PAGE:
          return {...state, currentPage: action.payload}
        case actionTypes.SET_ROWS_PER_PAGE:
          return {...state, rowsPerPage: action.payload}
        case actionTypes.SET_TOTAL_PAGES:
          return {...state, totalPages: action.payload}
        case actionTypes.RESET_PAGINATION_STATE:
          return initialState
        default:
          return state
      }
}
export default reducer;