import * as actionTypes from '../actions/actionTypes'

const initialState = {
  currentModalPage: 1,
  rowsModalPerPage: 5,
  totalModalPages: 1,
}

const reducer = (state = initialState, action)=>{
    switch (action.type) {
        case actionTypes.SET_MODAL_CURRENT_PAGE:
          return {...state, currentModalPage: action.payload}
        case actionTypes.SET_ROWS_PER_MODAL_PAGE:
          return {...state, rowsModalPerPage: action.payload}
        case actionTypes.SET_TOTAL_MODAL_PAGES:
          return {...state, totalModalPages: action.payload}
        case actionTypes.RESET_MODAL_PAGINATION_STATE:
          return initialState
        default:
          return state
      }
}
export default reducer;