import { Helmet } from "react-helmet-async";

const UTC2Helmet = ({ title, description, image, canonical, url }) => {
  return (
    <Helmet>
      {title && <meta name="title" content={title} />}
      {description && <meta name="description" content={description} />}
      {url && <meta name="url" content={url} />}
      {canonical && <link rel="canonical" href={canonical} />}

      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:image" content={image} />}
      {image && <meta property="og:description" content={description} />}
      {url && <meta property="og:url" content={url} />}

      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta property="twitter:image" content={image} />}
      {url && <meta property="twitter:url" content={url} />}
    </Helmet>
  );
};

export default UTC2Helmet;
