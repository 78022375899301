import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import React from "react";
import ReactDOM from "react-dom/client";
import { CookiesProvider } from "react-cookie";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";

import store from "./store/createStore";
import App from "./App";
import { icons } from "./assets/svg";
import "./polyfill";
import UTC2Helmet from "./sharedComponents/UTC2Helmet/UTC2Helmet";
import configuration from "./configuration";

React.icons = icons;

const helmetContext = {};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <HelmetProvider context={helmetContext}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </HelmetProvider>
  </Provider>
);
