import * as actionTypes from "../actions/actionTypes";

const initialState = {
  searchKey: "",
  searchResults: [],
  totalPages: 1,
};

const setSearchResults = (state, payload) => {
  if (!payload && !payload?.responseData) return state;
  return {
    ...state,
    searchResults: payload?.responseData?.rows,
    totalPages: payload?.responseData?.totalPages,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH_KEY:
      return { ...state, searchKey: action.payload };
    case actionTypes.SET_SEARCH_RESULTS:
      return setSearchResults(state, action.payload);
    case actionTypes.RESET_SEARCH_RESULTS:
      return { ...state, searchKey: "", searchResults: [], totalPages: 1 };
    default:
      return state;
  }
};
export default reducer;
