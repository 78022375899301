import React, { Component, useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import Favicon from "react-favicon";
import AOS from "aos";
import ReactGA from "react-ga4";
import configuration from "./configuration";

import "aos/dist/aos.css";
import "suneditor/dist/css/suneditor.min.css";
import "antd/dist/reset.css";
import "./scss/style.scss";

// Analytics
ReactGA.initialize(configuration.analyticsGoogle);

// Containers
const TheLayout = React.lazy(() => import("./layouts/TheLayout"));
const ToastMessage = React.lazy(() => import("./layouts/toast-message/index"));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

class App extends Component {
  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <>
        <Router>
          {/* <Favicon url={[`${configuration.siteURL}logo-icon.png`]} /> */}
          <ScrollToTop />
          <TheLayout />
          <ToastMessage maxCount={4} filter position="top-right" />
        </Router>
      </>
    );
  }
}

export default App;
