import * as actionTypes from "../actions/actionTypes";

const initialState = {
  pageList: [],
  pageConfigTab: {},
  department: {},
  studyList: {},
};

const setPageList = (state, payload) => {
  if (!payload && !payload?.responseData) return state;
  return {
    ...state,
    pageList: payload?.responseData,
  };
};

const setPageConfigTab = (state, payload) => {
  if (!payload && !payload?.responseData) return state;
  return {
    ...state,
    pageConfigTab: payload?.responseData,
  };
};

const setDepartment = (state, payload) => {
  if (!payload) return state;
  return {
    ...state,
    department: payload,
  };
};

const setStudyList = (state, payload) => {
  if (!payload) return state;
  return {
    ...state,
    studyList: payload,
  };
};
const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.GET_ALL_PAGE_CONFIG:
      return setPageList(state, payload);
    case actionTypes.GET_PAGE_CONFIG_TAB:
      return setPageConfigTab(state, payload);
    case actionTypes.SET_DEPARTMENT:
      return setDepartment(state, payload);
    case actionTypes.SET_STUDYLIST:
      return setStudyList(state, payload);
    default:
      return state;
  }
};
export default reducer;
