import * as actionTypes from "../actions/actionTypes";

const userInitialStates = {
  adminsList: null,
  permissionsList: null,
};

const setAdminsList = (state, payload) => {
  if (!payload && !payload?.responseData) return state;
  return {
    ...state,
    adminsList: payload?.responseData?.rows,
  };
};

const setPermissionsList = (state, payload) => {
  if (!payload && !payload?.responseData) return state;
  return {
    ...state,
    permissionsList: payload?.responseData,
  };
};

const reducer = (state = userInitialStates, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.GET_ALL_ADMINS:
      return setAdminsList(state, payload);
    case actionTypes.GET_ALL_PERMISSIONS:
      return setPermissionsList(state, payload);
    default:
      return state;
  }
};
export default reducer;
