const initialState = {
  sidebarShow: false //'responsive'
}

const reducer = (state = initialState, {type, ...rest})=>{
    switch (type) {
        case 'setSidebarShow':
          return {...state, ...rest }
        default:
          return state
      }
}
export default reducer;