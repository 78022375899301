import { applyMiddleware, createStore } from "redux";
import ReduxThunk from "redux-thunk";
import rootReducer from "./reducers";
import env from "../configuration";

export const middleware = [ReduxThunk];
export const createStoreWithMiddleware = applyMiddleware(...middleware)(
  createStore
);

const store = env.isProdEnv
  ? createStoreWithMiddleware(rootReducer)
  : createStoreWithMiddleware(
      rootReducer,
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    );
export default store;
