import * as actionTypes from "../actions/actionTypes";
import { size } from 'lodash'
import axios from "../../helper/axios"

const initState = () => {
	return {
		fileProgress: {
			// format will be like below
			// 1: {  --> this interpreted as uploaded file #1
			//   id: 1,
			//   file,
			//   progress: 0,
			// },
		}
	};
};
const initialState = initState();
const STATUS_UPLOAD = {
	failed: -1, //
	init: 0, //
	uploading: 1, //
	success: 2, //
}

const modifyFiles = (existingFiles, files, belongTo = "") => {
	let fileToUpload = {}
	for (let i = 0; i < files.length; i++) {
	  const id = size(existingFiles) + i + 1
	  const CancelToken = axios.request.CancelToken
      const cancelSource = CancelToken.source()
	  fileToUpload = {
		...fileToUpload,
		[id]: {
		  id,
		  belongTo,
		  file: files[i],
		  progress: 0,
		  cancelSource, 
		  status: STATUS_UPLOAD.init, 
		},
	  }
	}
  
	return fileToUpload
}


const clearFiles = (state, payload) => {
	return initState();
};
const setFiles = (state, payload) => {
	if (!payload) return state
	const setFiles = {
        ...state,
        fileProgress: {
			...state.fileProgress,
			...modifyFiles(state.fileProgress, payload.files, payload.id),
		}
	};
	return setFiles;
};
const unsetFile = (state, payload) => {
	if (!payload) return state

	const index = payload.index
	const oldState = state.fileProgress
	delete oldState[parseInt(index)]
	const newState = Object.keys(oldState).filter(key => key !== index).map((key) => {
		if ((parseInt(key)) > index) {
			oldState[parseInt(key) - 1] = {...oldState[key], id: oldState[key].id - 1}
			return oldState[parseInt(key) - 1]
		}
		return oldState[parseInt(key)]
	})
	const newFileProgress = newState.length ? newState.reduce((acc, curr) => ({ ...acc, [curr.id]: curr}), {}) : {}
	const unsetFile = {
		...state, 
		fileProgress: { ...newFileProgress, },
	}	
	return unsetFile
}
const unsetFiles = (state, payload) => {
	if (!payload) return state

	const id = payload.id
	const oldStateFileProgress = state.fileProgress

	const filtered = Object.keys(oldStateFileProgress)
	.filter(key => oldStateFileProgress[key].belongTo !== id) // filtering
	const test = filtered.map(key => oldStateFileProgress[key]) // map to get property of obj
	.reduce((acc, curr, index) => ({ ...acc, [index + 1]: {...curr, id: index + 1}}), {}) // set id for each element

	const unsetFiles = {
		...state, 
		fileProgress: { ...test, },
	}	
	return unsetFiles
}
const setUploadProgress = (state, payload) => {
	if (!payload) return state
	const setUploadProgress = {
        ...state,
		fileProgress: {
		  ...state.fileProgress,
		  [payload.id]: {
			...state.fileProgress[payload.id],
			progress: payload.progress,
			status: STATUS_UPLOAD.uploading,
		  },
		},
	}
	return setUploadProgress;
};
const setSuccessProgress = (state, payload) => {
	if (!payload) return state
	const setFiles = {
        ...state,
		fileProgress: {
			...state.fileProgress,
			[payload.id]: {
				...state.fileProgress[payload.id],
				status: STATUS_UPLOAD.success,
			},
		},
	};
	return setFiles;
};
const setFailedProgress = (state, payload) => {
	if (!payload) return state
	const setFiles = {
        ...state,
		fileProgress: {
			...state.fileProgress,
			[payload.id]: {
			  ...state.fileProgress[payload.id],
			  status: STATUS_UPLOAD.failed,
			  progress: 0,
			},
		  },
	};
	return setFiles;
};
const retryUploadFile = (state, payload) => {
	if (!payload) return state
	const CancelToken = axios.request.CancelToken
	const cancelSource = CancelToken.source()
	const retryUploadFile = {
		...state, 
		fileProgress: {
			...state.fileProgress,
			[payload.id]: {
				...state.fileProgress[payload.id],
				status: STATUS_UPLOAD.uploading,
				progress: 0,
				cancelSource,
			}
		}
	}
	return retryUploadFile
}

const reducer = (state = initialState, action) => {
	const { payload } = action;
	switch (action.type) {
		case actionTypes.CLEAR_UPLOAD_FILE:
			return clearFiles(state, payload);
		case actionTypes.SET_UPLOAD_FILE:
			return setFiles(state, payload);
		case actionTypes.UNSET_UPLOAD_FILE:
			return unsetFile(state, payload);
		case actionTypes.UNSET_UPLOAD_FILES:
			return unsetFiles(state, payload);
		case actionTypes.SET_UPLOAD_PROGRESS:
			return setUploadProgress(state, payload);
		case actionTypes.SUCCESS_UPLOAD_FILE:
			return setSuccessProgress(state, payload);
		case actionTypes.FAILURE_UPLOAD_FILE:
			return setFailedProgress(state, payload);
		case actionTypes.RETRY_UPLOAD_FILE:
			return retryUploadFile(state, payload);
		default:
			return state;
	}
};

export default reducer;
export { STATUS_UPLOAD, }