const isProdEnv = process.env.REACT_APP_ENV === "prod";
const configuration = {
  isProdEnv,
  analyticsGoogle: "G-C9TEK9BS4C",
  apiEndpoint: "https://kxm.vn/api/v1.0",
  imageEndpoint: "https://kxm.vn",
  ssoDomain: "http://27.74.255.96:8090/auth/loginqlts",
  channel: "utc",
  siteURL: "https://kxm.vn/"
}
export default configuration;
