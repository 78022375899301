import * as actionTypes from "../actions/actionTypes";
import sliceKeys from "../sliceKeys";

const initState = () => {
  const token = localStorage.getItem(sliceKeys.auth);
  const user = localStorage.getItem(sliceKeys.user);
  const result = {};
  if (token) {
    result[sliceKeys.auth] = token;
  }
  if (user) {
    result[sliceKeys.user] = JSON.parse(user);
  }
  return result;
};
const initialState = initState();

const resetToken = () => {
  localStorage.removeItem(sliceKeys.auth);
  localStorage.removeItem(sliceKeys.user);
  return {};
};

const setToken = (state, payload) => {
  if (!payload || !payload.responseData?.accessToken) return state;
  const token = payload.responseData?.accessToken;
  const setToken = {
    ...state,
    [sliceKeys.auth]: token,
  };
  localStorage.setItem(sliceKeys.auth, token);
  return setToken;
};

const setUser = (state, payload) => {
  if (!payload || !payload.data) return state;
  const userInfo = payload.data?.responseData;
  const setUser = {
    ...state,
    user: payload.data?.responseData,
  };
  localStorage.setItem(sliceKeys.user, JSON.stringify(userInfo));
  return setUser;
};

const reducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.SET_TOKEN:
      return setToken(state, payload);
    case actionTypes.SET_USER:
      return setUser(state, payload);
    case actionTypes.RESET_TOKEN:
      return resetToken();
    default:
      return state;
  }
};

export default reducer;
