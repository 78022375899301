import * as actionTypes from '../actions/actionTypes'

const initialState = {
    showLoader: true
  }

const reducer = (state = initialState, action)=>{
    switch (action.type) {
        case actionTypes.START_LOADING:
          return {...state, showLoader: true}
        case actionTypes.STOP_LOADING:
          return {...state, showLoader: false}
        default:
          return state
      }
}
export default reducer;