import * as actionTypes from "../actions/actionTypes";

const initialState = {
  list: [],
  totalPages: 1,
  count: 0,
  groups: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATION:
      return {
        ...state,
        list: action.payload?.responseData
          ? action.payload?.responseData.rows
          : [],
        totalPages: action.payload?.responseData
          ? action.payload?.responseData.totalPages
          : 1,
        count: action.payload?.responseData
          ? action.payload?.responseData.count
          : 0,
      };
    case actionTypes.GET_NOTIFICATION_GROUP:
      const groups = action.payload?.responseData;
      groups.totalUnRead = 0;
      groups.forEach((element) => {
        groups.totalUnRead += element.unRead;
      });
      return {
        ...state,
        groups: action.payload?.responseData,
      };
    case actionTypes.RESET_NOTIFICATION:
      return { ...state, list: [], totalPages: 1, count: 0 };
    case actionTypes.HAS_NEW_NOTIFICATION:
      return { ...state, ...action };
    default:
      return state;
  }
};
export default reducer;
