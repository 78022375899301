export const NO_ACTION = "NO_ACTION";
// =======================Loader action types=======================
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
// =======================Toast Message action types=======================
export const API_MESSAGE = "API_MESSAGE";
export const SET_API_MESSAGE = "SET_API_MESSAGE";
export const CLEAR_API_MESSAGE = "CLEAR_API_MESSAGE";
export const CLEAR_ONE_API_MESSAGE = "CLEAR_ONE_API_MESSAGE";
// =======================Login action types=======================
export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const SET_TOKEN = "SET_TOKEN";
export const RESET_TOKEN = "RESET_TOKEN";
export const UNAUTHENTICATED = "UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const GET_USER = "GET_USER";
export const SET_USERS = "SET_USERS";
// =======================Status action types=======================
export const SET_STATUS = "SET_STATUS";
// =======================Upload Files action types=======================
export const CLEAR_UPLOAD_FILE = "CLEAR_UPLOAD_FILE";
export const SET_UPLOAD_FILE = "SET_UPLOAD_FILE";
export const UNSET_UPLOAD_FILE = "UNSET_UPLOAD_FILE";
export const UNSET_UPLOAD_FILES = "UNSET_UPLOAD_FILES";
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";
export const SUCCESS_UPLOAD_FILE = "SUCCESS_UPLOAD_FILE";
export const FAILURE_UPLOAD_FILE = "FAILURE_UPLOAD_FILE";
export const RETRY_UPLOAD_FILE = "RETRY_UPLOAD_FILE";
// =======================Pagination action types=======================
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_ROWS_PER_PAGE = "SET_ROWS_PER_PAGE";
export const SET_TOTAL_PAGES = "SET_TOTAL_PAGES";
export const RESET_PAGINATION_STATE = "RESET_PAGINATION_STATE";
// =======================DROPDOWN Pagination action types=======================
export const SET_DROPDOWN_CURRENT_PAGE = "SET_DROPDOWN_CURRENT_PAGE";
export const SET_ROWS_PER_DROPDOWN_PAGE = "SET_ROWS_PER_DROPDOWN_PAGE";
export const SET_TOTAL_DROPDOWN_PAGES = "SET_TOTAL_DROPDOWN_PAGES";
export const RESET_DROPDOWN_PAGINATION_STATE =
  "RESET_DROPDOWN_PAGINATION_STATE";
// =======================Modal Pagination action types=======================
export const SET_MODAL_CURRENT_PAGE = "SET_MODAL_CURRENT_PAGE";
export const SET_ROWS_PER_MODAL_PAGE = "SET_ROWS_PER_MODAL_PAGE";
export const SET_TOTAL_MODAL_PAGES = "SET_TOTAL_MODAL_PAGES";
export const RESET_MODAL_PAGINATION_STATE = "RESET_MODAL_PAGINATION_STATE";

// =======================Notification=======================
export const GET_WEB_TOKEN = "POST_WEB_TOKEN";
// =======================Notification types=======================
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_GROUP = "GET_NOTIFICATION_GROUP";
export const RESET_NOTIFICATION = "RESET_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const HAS_NEW_NOTIFICATION = "HAS_NEW_NOTIFICATION";

// =======================Menu types=======================
export const GET_MENU = "GET_MENU";

// =======================Post types=======================
export const GET_ALL_POST = "GET_ALL_POST";

// =======================Page config types=======================
export const GET_ALL_PAGE_CONFIG = "GET_ALL_PAGE_CONFIG";
export const GET_PAGE_CONFIG_TAB = "GET_PAGE_CONFIG_TAB";
export const SET_DEPARTMENT = "SET_DEPARTMENT";
export const SET_STUDYLIST = "SET_STUDYLIST";

// =======================Search result types=======================
export const SET_SEARCH_KEY = "SET_SEARCH_KEY";
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";
export const RESET_SEARCH_RESULTS = "RESET_SEARCH_RESULTS";

// =======================Statistic types=======================
export const GET_SITE_ACCESS_STATISTIC = "GET_SITE_ACCESS_STATISTIC";

// =======================User types=======================
export const GET_ALL_ADMINS = "GET_ALL_ADMINS";
export const GET_ALL_PERMISSIONS = "GET_ALL_PERMISSIONS";
