import { combineReducers } from "redux";
import auth from "./auth";
import layout from "./layout";
import loader from "./loader";
import message from "./message";
import uploadFiles from "./uploadFiles";
import pagination from "./pagination";
import notification from "./notification";
import dropdownPagination from "./dropdownPagination";
import modalPagination from "./modalPagination";
import post from "./post";
import pageConfig from "./pageConfig";
import searchResult from "./searchResult";
import user from "./user";

export default combineReducers({
  auth,
  layout,
  loader,
  message,
  uploadFiles,
  pagination,
  notification,
  dropdownPagination,
  modalPagination,
  post,
  pageConfig,
  searchResult,
  user,
});
