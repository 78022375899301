import * as actionTypes from "../actions/actionTypes";

const initialState = {
  postList: [],
};

const setPostList = (state, payload) => {
  if (!payload && !payload?.responseData) return state;
  return {
    ...state,
    postList: payload?.responseData?.rows,
  };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.GET_ALL_POST:
      return setPostList(state, payload);
    default:
      return state;
  }
};
export default reducer;
